<template>
  <v-card class="ma-2" :height="height">
    <v-layout>
      <v-flex shrink>
        <v-navigation-drawer
          v-model="drawer" :mini-variant.sync="mini" permanent :height="height - 17"
          :color="($vuetify.theme.dark) ? '' : ''" width="100%"
        >
          <v-list class="pa-3">
            <v-list-item-group v-model="selectedItem" color="primary" mandatory>
              <v-list-item v-for="(item, index) in menuItems" :key="index" class="px-12 py-6">
                <v-icon v-text="item.icon" size="21" left></v-icon>
                <span class="caption font-weight-medium"> {{ item.title }} </span>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </v-flex>
      <v-flex>
        <v-card :max-height="height - 17" class="overflow-y-auto" flat>
          <v-form ref="form">
            <v-card-text v-if="selectedItem === 0">
              <v-card-subtitle class="font-weight-black">
                <v-layout>
                  <v-flex>
                    General Information
                  </v-flex>
                  <v-spacer></v-spacer>
                  <v-btn color="success" class="mx-2" @click="SaveUpdateHandler" tile> Save </v-btn>
                </v-layout>
              </v-card-subtitle>
              <v-divider></v-divider>
              <v-row class="mt-2">
                <v-col cols="12" lg="3" md="6" sm="3">
                  <v-text-field outlined dense hide-details label="Institute Name" v-model="appObj.name"></v-text-field>
                </v-col>
                <v-col cols="12" lg="3" md="6" sm="3">
                  <v-text-field outlined dense hide-details label="Institute Code" v-model="appObj.code"></v-text-field>
                </v-col>
                <v-col cols="12" lg="3" md="6" sm="3">
                  <vuetify-tel-input
                    outlined
                    dense
                    v-model="appObj.phone"
                    :preferred-countries="['id', 'gb', 'ua', 'us']"
                    :valid-characters-only="true"
                    select-label="Country"
                  />
                </v-col>
                <v-col cols="12" lg="3" md="6" sm="3">
                  <v-text-field outlined dense hide-details label="Email" v-model="appObj.email"></v-text-field>
                </v-col>
                <v-col cols="12" lg="3" md="6" sm="3">
                  <v-file-input append-icon="mdi-camera" outlined dense hide-details label="App Logo Small" v-model="appLogo">
                    <template v-slot:append v-if="tabLogo">
                      <v-img :src="tabLogo" small height="40" width="40" contain :key="reRender"></v-img>
                    </template>
                  </v-file-input>
                </v-col>
                <v-col cols="12" lg="3" md="6" sm="3">
                  <v-autocomplete outlined dense hide-details :items="timeZonesList" label="Timezone" item-text="displayName" item-value="value" v-model="appObj.timezone" rows="3"></v-autocomplete>
                </v-col>
                <v-col cols="12" lg="6" md="6" sm="3">
                  <v-textarea outlined dense hide-details label="Address" v-model="appObj.address" rows="3"></v-textarea>
                </v-col>
              </v-row>
              <v-card-subtitle class="font-weight-black">Institute Location</v-card-subtitle>
              <v-divider></v-divider>
              <leaflet-map :locationObj.sync="locationObj" :payload="payload" :key="reInit" />
            </v-card-text>
            <academic-year v-else-if="selectedItem === 1"></academic-year>
            <shift-settings v-else-if="selectedItem === 2"></shift-settings>
            <work-week v-else-if="selectedItem === 3" class="pt-4 pr-4"></work-week>
            <infrastructure v-else-if="selectedItem === 4"></infrastructure>
            <communication-settings v-else-if="selectedItem === 5"></communication-settings>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
  </v-card>
</template>
<script>
export default {
  data () {
    return {
      height: window.innerHeight - 77,
      drawer: false,
      selectedItem: 0,
      menuItems: [{
        title: 'General Information',
        icon: 'mdi-home-city'
      }, {
        title: 'Academic Year',
        icon: 'mdi-calendar'
      }, {
        title: 'Shifts',
        icon: 'mdi-timelapse'
      }, {
        title: 'Work week',
        icon: 'mdi-calendar-week',
      }, {
        title: 'Infrastructure',
        icon: 'mdi-city'
      }, {
        title: 'Communication',
        icon: 'mdi-cellphone-message'
      }],
      mini: false,
      reInit: 0,
      locationObj: {
        markers: [
          {
            id: 'm1',
            position: { lat: 51.47, lng: -0.09 },
            tooltip: 'Institute Location',
            draggable: true,
            visible: true,
          }
        ]
      },
      payload: {
        showLapInfo: false,
        showConfiguration: false,
        allowMultipleMarker: false
      },
      appLogo: null,
      appObj: {
        smtp: {}
      },
      logo: null,
      reRender: 0,
      timeZonesList: [],
      dateFormatsList: ['DD.MM.YYYY', 'YYYY-MM-DD', 'DD-MM-YYYY']
    }
  },
  components: {
    'vuetify-tel-input': () => import('@/components/PhoneInput/vue-tel-input-vuetify.vue'),
    'shift-settings': () => import('./Modules/ShiftSettings.vue'),
    'communication-settings': () => import('./Modules/CommunicationSettings.vue'),
    'academic-year': () => import('./Modules/AcademicYear.vue'),
    'infrastructure': () => import('./Modules/Infrastructure.vue'),
    'leaflet-map': () => import('@/components/LeafletMap.vue'),
    'work-week': () => import('./Modules/WorkWeek.vue')
  },
  mounted () {
    this.getTimezonesList()
    this.getSettingHandler()
    this.appObj = this.appSettings ? this.$_copy(this.appSettings) : {}
    if (!this.appObj.smtp) this.appObj.smtp = {}

    window.onresize = () => {
      this.height = window.innerHeight - 77
    }
  },
  methods: {
    getTimezonesList () {
      this.$_execute('get', 'timezone-list').then(({ data }) => {
        this.timeZonesList = data
      })
    },
    getSettingHandler () {
      this.$_execute('get', 'appSettings').then(({ data }) => {
        if (data) {
          this.appObj = data
          if (this.appObj.location) {
            this.locationObj.markers[0].position = this.appObj.location
            this.reInit++
          }
          if (!this.appObj.smtp) this.appObj.smtp = {}
        }
      })
    },
    SaveUpdateHandler () {
      delete this.appObj.settings
      this.appObj.location = this.locationObj.markers[0].position
      this.$_execute('post', 'appSettings', this.appObj).then(response => {
        if (response.data && response.data.name) document.querySelector('title').textContent = response.data.name
        this.$store.commit('updateAppSettings', response.data)
        this.$root.$emit('snackbar', { message: 'Updated Successfully', color: 'success' })
      })
      if (this.appLogo) {
        let logo = this.$jinglee.getBinary(this.appLogo)
        this.$_execute('put', 'update-logo', logo).then(response => {
          if (response.data) {
            this.reRender += 1
          this.$store.commit('updateTabLogo', response.data)
            let link = document.querySelector('#logo')
            link.href = ''
            link.href = `${ process.env.VUE_APP_IMAGE_URL }logo/${ response.data }`
            this.appLogo = null
            this.$root.$emit('snackbar', { message: 'Logo Updated Successfully', color: 'success' })
          }
        }).catch(err => this.$root.$emit('snackbar', { message: err, color: 'error' }))
      }
    }
  }
}
</script>
